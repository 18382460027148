import { Organization, UpdateOrganizationInput } from '@esub-engineering/platform-types';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { organizationQueries } from '@esub-engineering/react-common-lib';
import { useCallback } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { useToast } from '../../utilityHooks';

export const useUpdateOrganization = (
  options: UseMutationOptions<Organization, Error, { input: UpdateOrganizationInput }> = {}
) => {
  const { openErrorToast, openSuccessToast } = useToast();

  // query
  const client = useGraphQLClient();

  const mutateCallback = useCallback(
    async (org) => {
      const data = await client!.request(organizationQueries.updateOrganization, org);
      return data.updateOrganization;
    },
    [client]
  );

  return useMutation<Organization, Error, { input: UpdateOrganizationInput }>(mutateCallback, {
    mutationKey: organizationQueries.key,
    onSuccess: () => {
      openSuccessToast('Changes saved');
    },
    onError: () => {
      openErrorToast('Unable to save the organization');
    },
    ...options,
  });
};

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { organizationQueries } from '@esub-engineering/react-common-lib';
import { DataTable } from './DataTable';
import { IOrganizationsList, IOrganizationItem } from './types';
import { openToast } from '../../redux/toast';

const OrganizationList = () => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<string | null>(null);
  const [debouncedFilter] = useDebounce(filter, 500);

  const client = useGraphQLClient();

  const { data, isLoading } = useQuery<IOrganizationsList, Error>(
    [organizationQueries.key, debouncedFilter],
    () =>
      client!.request(organizationQueries.getOrganizations, {
        filter: debouncedFilter,
      }),
    {
      onError: () => {
        dispatch(
          openToast({
            type: 'error',
            message: 'Unable to fetch list of organizations',
          })
        );
      },
    }
  );

  let organizationList: Array<IOrganizationItem> = [];

  // TODO: might need to be refactored with react-table
  if (data) {
    organizationList = data.listOrganizations.items.map(
      ({ subscriptionEnd, subscriptionStart, ...rest }) => ({
        subscriptionEnd: new Date(Date.parse(subscriptionEnd)).toLocaleDateString(),
        subscriptionStart: new Date(Date.parse(subscriptionStart)).toLocaleDateString(),
        ...rest,
      })
    );
  }

  const filterChangeHandler = (filterValue: string) => {
    if (!filterValue) {
      setFilter(null);
    } else {
      setFilter(filterValue);
    }
  };

  return (
    <DataTable
      data={organizationList}
      onFilterChange={filterChangeHandler}
      filterLabel="Organization Name"
      filterValue={filter || ''}
      loading={isLoading}
    />
  );
}

export { OrganizationList };

import { CreateLicenseOrgAllocationInput, LicenseOrgAllocation } from "@esub-engineering/platform-types";
import { useGraphQLClient } from "@esub-engineering/common-containers";
import { licenseQueries } from "@esub-engineering/react-common-lib";
import { useCallback } from "react";
import { useMutation } from "react-query";
import { useToast } from "../../utilityHooks";

export const useCreateLicenseOrgAllocation = () => {
  const client = useGraphQLClient();
  const { openErrorToast, openSuccessToast } = useToast();

  const mutateCallback = useCallback(
    async (input) => {
      const data = await client!.request(licenseQueries.createLicenseOrgAllocation, input)
      return data.createLicenseOrgAllocation
    },
    [client]
  );

  return useMutation<LicenseOrgAllocation, Error, CreateLicenseOrgAllocationInput>(
    mutateCallback,
    {
      mutationKey: [licenseQueries.key, 'allocate'],
      onSuccess: () => openSuccessToast('Licenses allocated to org.'),
      onError: () => openErrorToast('Unable to save the licenses'),
    }
  );
}

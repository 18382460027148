import React, { useCallback } from 'react';
import { useField } from 'react-form';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';
import useStyles from './styles';
import { chipMap, options } from './values';

export const AccountingIntegrationsField = React.memo(
  ({
    id = 'AccountingIntegrationsField',
    fieldName = 'accountingIntegrations',
    errorMessage = 'Selection required',
    isEditing,
    isDisabled = false,
    onChange,
  }: Partial<IEditableField> & {
    onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
    isDisabled?: boolean;
  }) => {
    const classes = useStyles();

    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );

    const {
      value = [],
      setValue,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
      if (onChange) onChange(event);
      setValue(event.target.value as string);
    };

    return !isEditing ? (
      <DataField label="Enabled Accounting Integration" value={value} />
    ) : (
      <FormControl error={!!error} fullWidth>
        <InputLabel id="EnabledAccountingIntegrationsSelect" error={!!error} required>
          Enabled Accounting Integration
        </InputLabel>
        <Select
          inputProps={{
            'aria-label': 'Enabled Accounting Integration',
            'data-testid': 'enabled-AccountingIntegrations-select',
          }}
          value={value}
          id={id}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) =>
            selected ? (
              <div className={classes.chips}>
                {/* @ts-ignore-next-line */}
                <Chip label={chipMap[selected]} className={classes.chip} />
              </div>
            ) : (
              <div />
            )
          }
          error={!!error}
          multiple={false}
          disabled={isDisabled}
          required
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

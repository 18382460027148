import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'react-form';
import { DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const NameField = React.memo(
  ({
    id = 'NameField',
    fieldName = 'name',
    errorMessage = 'A name is required',
    isEditing,
  }: Partial<IEditableField>) => {
    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );

    const {
      value = '',
      getInputProps,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    return !isEditing ? (
      <DataField label="Name" value={value} />
    ) : (
      <TextField
        {...getInputProps()}
        id={id}
        value={value}
        inputProps={{
          'aria-label': 'Name',
        }}
        label="Name"
        error={!!error}
        helperText={error}
        fullWidth
        required
      />
    );
  }
);

import React from 'react';
import { Container, Paper, Box } from '@material-ui/core';
import { ConfirmationPasswordSetWrapper } from '@esub-engineering/common-containers';
import { Page } from '@esub-engineering/react-component-library';
import { EmptyPageWrapper } from '../../../containers';

export const ConfirmationPasswordSetPage = () => (
  <EmptyPageWrapper>
    <Page>
      <Container maxWidth="sm">
        <br />
        <Paper>
          <Box m={2} p={2}>
            <ConfirmationPasswordSetWrapper />
          </Box>
        </Paper>
      </Container>
    </Page>
  </EmptyPageWrapper>
);

import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'react-form';
import { DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const Address2Field = React.memo(
  ({ id = 'Address2Field', fieldName = 'address.street2', isEditing }: Partial<IEditableField>) => {
    const { value = '', getInputProps } = useField(fieldName, {});

    return !isEditing ? (
      <DataField label="Address Line 2" value={value} />
    ) : (
      <TextField
        {...getInputProps()}
        inputProps={{
          'aria-label': 'Address Line 2',
        }}
        label="Address Line 2"
        id={id}
        value={value}
        fullWidth
      />
    );
  }
);

import React, { useMemo, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { Panels, Toast, ESubMinimal, Settings, UserProfile, Icon } from '@esub-engineering/react-component-library';
import { clearLogin } from '@esub-engineering/react-common-lib';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IPageWrapperProps } from './types';
import { closeToast, ToastType } from '../../redux/toast';

export const PageWrapper = ({ children }: IPageWrapperProps) => {
  // redux
  const dispatch = useDispatch();
  const match = useRouteMatch<{ entity: string; }>('/:entity')

  const { open, message, autoHideDuration, type } = useSelector<{ toast: ToastType }, ToastType>(
    (state) => state.toast
  );

  // state
  const [profileEl, setProfileEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setProfileEl(null);

  // router
  const history = useHistory();

  // navigation
  const menuOptions = useMemo(
    () => [
      {
        id: 'SettingsMenuButton',
        icon: (
          <Icon>
            <Settings />
          </Icon>
        ),
        label: 'Settings',
        onClick: () => {},
        selectionKey: 'Settings',
      },
    ],
    []
  );

  const secondaryMenuOptions = useMemo(
    () => [
      {
        id: 'UserProfileMenuButton',
        icon: <UserProfile />,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          setProfileEl(event.currentTarget);
        },
      },
    ],
    []
  );

  const slideoutOptions = useMemo(
    () => ({
      header: {
        icon: <Settings />,
        title: 'Settings',
      },
      sections: [
        {
          title: 'Client Configuration',
          items: [
            {
              id: 'OrganizationsDrawerButton',
              text: 'Organizations',
              selectionKey: 'organizations',
              onClick: () => {
                history.push('/organizations');
              },
            },
            {
              id: 'LicensesDrawerButton',
              text: '3rd Party Licenses',
              selectionKey: 'licenses',
              onClick: () => {
                history.push('/licenses');
              },
            },
          ],
        },
      ],
    }),
    [history]
  );

  return (
    <>
      <Panels
        selectedMenuOption="Settings"
        selectedMenuListOption={match?.params.entity}
        menuOptions={menuOptions}
        secondaryMenuOptions={secondaryMenuOptions}
        slideoutOptions={slideoutOptions}
        logo={<ESubMinimal tertiaryColor="#ffffff" />}
        drawerWidth={310}
        menuWidth={90}
      >
        {children}
      </Panels>
      <Menu
        id="ProfileMenu"
        anchorEl={profileEl}
        open={Boolean(profileEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
      >
        <MenuItem
          onClick={() => {
            clearLogin();

            // TODO: create protected route so this is unnecessary
            history.push('/login');
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <Toast
        open={open}
        type={type}
        autoHideDuration={autoHideDuration}
        message={message}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => dispatch(closeToast())}
      />
    </>
  );
};

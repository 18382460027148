import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'react-form';
import { DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const UserCountField = React.memo(
  ({
    id = 'UserCountField',
    fieldName = 'userCount',
    errorMessage = 'User count is required',
    isEditing,
  }: Partial<IEditableField>) => {
    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );

    const {
      value = '',
      getInputProps,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    return !isEditing ? (
      <DataField label="User count" value={value} />
    ) : (
      <TextField
        {...getInputProps()}
        inputProps={{
          'aria-label': 'User count',
          'data-testid': 'user-count-select',
        }}
        label="User count"
        id={id}
        type="number"
        error={!!error}
        helperText={error}
        required
        fullWidth
      />
    );
  }
);

import { LicenseProducts, ListLicenseProductsInput } from "@esub-engineering/platform-types";
import { useGraphQLClient } from "@esub-engineering/common-containers";
import { licenseQueries } from "@esub-engineering/react-common-lib";
import { useCallback } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { useToast } from "../../utilityHooks";

export const useListLicenseProducts = (options:UseQueryOptions<
  ListLicenseProductsInput, 
  Error, 
  LicenseProducts
>={}) => {
  const { openErrorToast } = useToast();

  const client = useGraphQLClient();

  const callback = useCallback(async (input) => {
    const licenses = await client!.request(licenseQueries.listLicenseProducts, {
      limit: 20,
      ...input
    })
    return licenses.listLicenseProducts || licenses
  },[client])

  return useQuery<
    ListLicenseProductsInput, 
    Error, 
    LicenseProducts
  >(
    [licenseQueries.key],
    callback,
    {
      onError: () => openErrorToast('Unable to fetch list of licenses'),
      ...options,
    }
  );
}

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    formField: {
      '& > *': {
        paddingBottom: theme.spacing(2),
      },
    },
  })
);

export default useStyles;

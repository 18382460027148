import { UpdateIntegrationsAdminSettingsInput, IntegrationsSettings } from "@esub-engineering/platform-types";
import { useGraphQLClient } from "@esub-engineering/common-containers";
import { integrationQueries } from "@esub-engineering/react-common-lib";
import { useCallback } from "react";
import { useMutation } from "react-query";
import { useToast } from "../../utilityHooks";

export const useUpdateIntegrationsAdminSettings = () => {
  const client = useGraphQLClient();
  const { openErrorToast, openSuccessToast } = useToast();

  const mutateCallback = useCallback(
    async (input) => {
      const data = await client!.request(integrationQueries.updateIntegrationsAdminSettings, {input})
      return data.updateIntegrationsAdminSettings
    },
    [client]
  );

  return useMutation<IntegrationsSettings, Error, UpdateIntegrationsAdminSettingsInput>(
    mutateCallback,
    {
      mutationKey: [integrationQueries.key, 'update'],
      onSuccess: () => openSuccessToast('integrations updated.'),
      onError: () => openErrorToast('Unable to save the integration changes'),
    }
  );
}

import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';

import useStyles from './styles';

export interface IFormProps {
  children: React.ReactNode;
  formError?: string;
  isEditing?: boolean;
  canSubmit?: boolean;
  error?: Error | null;
  buttonLabel?: string;
}

export const Form = ({
  children,
  formError,
  isEditing,
  canSubmit,
  error,
  buttonLabel,
}: IFormProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.formField}>
        {children}
        {formError && <Typography data-testid="errorMessage">{error?.message}</Typography>}
        {buttonLabel && isEditing && (
          <Box marginTop={2}>
            <Button
              variant="contained"
              color="primary"
              data-testid="submitButton"
              type="submit"
              aria-label={buttonLabel}
              disabled={!canSubmit}
              fullWidth
            >
              Submit
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};

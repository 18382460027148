import { AccountingIntegrationType } from "@esub-engineering/platform-types";

export const options = [
  {text: 'QuickBooks Online Advanced: Advanced Package', value: AccountingIntegrationType.QboAdvancedAdvanced},
  {text: 'QuickBooks Online Advanced: Base Package', value: AccountingIntegrationType.QboAdvancedBase},
  {text: 'QuickBooks Online Plus: Advanced Package', value: AccountingIntegrationType.QboPlusAdvanced},
  {text: 'QuickBooks Online Plus: Base Package', value: AccountingIntegrationType.QboPlusBase},
  // {text: 'QuickBooks Desktop Premier 2021 Base Package', value: 'QuickBooks Desktop Premier 2021 Base Package'},
  // {text: 'QuickBooks Desktop Premier 2022 Base Package', value: 'QuickBooks Desktop Premier 2022 Base Package'},
  // {text: 'QuickBooks Desktop Premier 2023 Base Package', value: 'QuickBooks Desktop Premier 2023 Base Package'},
  // {text: 'QuickBooks Desktop Premier 2021 Advanced Package', value: 'QuickBooks Desktop Premier 2021 Advanced Package'},
  // {text: 'QuickBooks Desktop Premier 2022 Advanced Package', value: 'QuickBooks Desktop Premier 2022 Advanced Package'},
  // {text: 'QuickBooks Desktop Premier 2023 Advanced Package ', value: 'QuickBooks Desktop Premier 2023 Advanced Package '},
  // {text: 'QuickBooks Desktop Enterprise 2021 Base Package', value: 'QuickBooks Desktop Enterprise 2021 Base Package'},
  // {text: 'QuickBooks Desktop Enterprise 2022 Base Package', value: 'QuickBooks Desktop Enterprise 2022 Base Package'},
  // {text: 'QuickBooks Desktop Enterprise 2023 Base Package', value: 'QuickBooks Desktop Enterprise 2023 Base Package'},
  // {text: 'QuickBooks Desktop Enterprise 2021 Advanced Package ', value: 'QuickBooks Desktop Enterprise 2021 Advanced Package '},
  // {text: 'QuickBooks Desktop Enterprise 2022 Advanced Package ', value: 'QuickBooks Desktop Enterprise 2022 Advanced Package '},
  // {text: 'QuickBooks Desktop Enterprise 2023 Advanced Package ', value: 'QuickBooks Desktop Enterprise 2023 Advanced Package '},
]

export const chipMap = {
  [AccountingIntegrationType.QboAdvancedAdvanced]:'QuickBooks Online Advanced: Advanced Package',
  [AccountingIntegrationType.QboAdvancedBase]:'QuickBooks Online Advanced: Base Package',
  [AccountingIntegrationType.QboPlusAdvanced]:'QuickBooks Online Plus: Advanced Package',
  [AccountingIntegrationType.QboPlusBase]:'QuickBooks Online Plus: Base Package',
} as {[key in AccountingIntegrationType]: string;}

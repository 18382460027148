import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-form';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Button, LoadingIndicator } from '@esub-engineering/react-component-library';
import {
  AccountingIntegrationType,
  CreateOrganizationInput,
  LicenseProductType,
  UpdateOrganizationInput,
  Modules,
} from '@esub-engineering/platform-types';
import { Address1Field } from '../Address1Field';
import { Address2Field } from '../Address2Field';
import { CityField } from '../CityField';
import { CountryField } from '../CountryField';
import { CountyField } from '../CountyField';
import { FaxField } from '../FaxField';
import { Form } from '../../components';
import { NameField } from '../NameField';
import { PhoneField } from '../PhoneField';
import { StateProvinceField } from '../StateProvinceField';
import { ZipCodeField } from '../ZipCodeField';
import { AccountStatusField, DateField, EnabledModulesField, UserCountField } from './components';
import { IOrganizationForm, IOrganization } from '../../types';
import { DrawingsUsersCountField } from './components/DrawingsUsersCountField';
import { useCreateLicenseOrgAllocation } from '../../ApiHooks/useCreateLicenseOrgAllocation';
import { useAddOrganization, useUpdateOrganization } from '../../ApiHooks';
import { useListLicenseOrgAllocationsAdmin } from '../../ApiHooks/useListLicenseOrgAllocationsAdmin';
import { useUpdateLicenseOrgAllocation } from '../../ApiHooks/useUpdateLicenseOrgAllocation';
import { AccountingIntegrationsField } from './components/AccountingIntegrationsField';
import { useUpdateIntegrationsAdminSettings } from '../../ApiHooks/useUpdateIntegrationsSettings';
import { openToast } from '../../redux/toast';

export const OrganizationForm = ({ data, isEditing, isNew, orgId }: IOrganizationForm) => {
  // router
  const history = useHistory();
  const orgRouteMatch = useRouteMatch('/organizations/:orgId');

  const dispatch = useDispatch();

  // form state
  const [enabledModules, setEnabledModules] = useState({
    drawings: false,
    integrations: false,
  });
  const { data: licenses, isLoading: loadingLicenses } = useListLicenseOrgAllocationsAdmin({
    enabled: enabledModules.drawings,
  });
  const orgLicenses = useMemo(
    () => licenses?.items.filter((l) => l.organization?.id === orgId) || [],
    [licenses, orgId]
  );

  const { mutateAsync: updateIntegrations } = useUpdateIntegrationsAdminSettings();
  const { mutateAsync: addLicenses, isLoading: isSavingLicenses } = useCreateLicenseOrgAllocation();
  const {
    mutateAsync: updateLicenses,
    isLoading: isUpdatingLicenses,
  } = useUpdateLicenseOrgAllocation();
  const {
    mutateAsync: updateOrganization,
    isLoading: organizationUpdating,
  } = useUpdateOrganization();
  const { mutateAsync: addOrganization, isLoading: addingOrganization } = useAddOrganization();

  const validate = (values: any) => {
    if (new Date(values.subscriptionEnd) <= new Date(values.subscriptionStart)) {
      return 'The subscription trial end date should be greater than the start date';
    }
    if (values.modules && values.modules.find((m: any) => m === LicenseProductType.Drawings)) {
      if (!values.drawingsUsersCount || values.drawingsUsersCount < 0) {
        return 'You must allocate licenses for the drawings module';
      }
    }
    return false;
  };

  const defaultFormValues = useMemo(
    () => ({
      name: data?.name,
      address: {
        ...data?.address,
      },
      phone: data?.phone,
      userCount: data?.userCount,
      fax: data?.fax,
      country: data?.country,
      accountStatus: data?.accountStatus,
      modules: data?.modules,
      subscriptionStart: data?.subscriptionStart,
      subscriptionEnd: data?.subscriptionEnd,
      drawingsUsersCount:
        orgLicenses.find((l) => l.product?.id === LicenseProductType.Drawings)?.totalLicenses || 0,
      accountingIntegrations: data?.integrationsSettings.accountingIntegration,
    }),
    [data, orgLicenses]
  );

  const isAccountingIntegrationDisabled = useMemo(
    () => Boolean(data?.integrationsSettings?.accountingIntegration),
    [data?.integrationsSettings?.accountingIntegration]
  );

  const {
    Form: ReactForm,
    meta: { canSubmit, isTouched },
    getFieldValue,
  } = useForm({
    defaultValues: defaultFormValues,
    validate,
    onSubmit: async (values) => {
      const {
        userCount,
        drawingsUsersCount,
        accountingIntegrations,
        ...rest
      }: Partial<IOrganization> & {
        drawingsUsersCount?: number;
        accountingIntegrations?: string;
      } = values;
      let org;

      if (isEditing && !isNew) {
        const input = {
          userCount: Number(userCount),
          id: data?.id,
          ...rest,
        } as UpdateOrganizationInput;
        org = await updateOrganization({ input });
      } else {
        const input = ({
          userCount: Number(userCount),
          ...rest,
        } as unknown) as CreateOrganizationInput;
        org = await addOrganization({ input });
        if (accountingIntegrations) {
          updateIntegrations({
            accountingIntegration: accountingIntegrations as AccountingIntegrationType,
            orgId: org.id,
          });
        }
      }

      if (
        accountingIntegrations &&
        rest.modules?.find((module) => module === Modules.Integrations) &&
        orgId
      ) {
        updateIntegrations({
          accountingIntegration: accountingIntegrations as AccountingIntegrationType,
          orgId,
        });
      } else {
        if (!orgId) return;
        updateIntegrations({
          accountingIntegration: null,
          orgId,
        });
      }

      if (!rest.modules?.find((m) => m === LicenseProductType.Drawings)) {
        history.push('/organizations');
        return;
      }

      if (!drawingsUsersCount) return;
      if (isEditing && orgLicenses.length > 0) {
        const licenseId = orgLicenses.find((m) => m.product?.id === LicenseProductType.Drawings)
          ?.id;
        if (!licenseId) return;
        await updateLicenses({
          orgId: org.id,
          id: licenseId,
          totalLicenses: drawingsUsersCount,
        });
        history.push('/organizations');
      } else {
        await addLicenses({
          orgId: org.id,
          productId: LicenseProductType.Drawings,
          totalLicenses: drawingsUsersCount,
        });
        history.push('/organizations');
      }
    },
    debugForm: false,
  });

  const skipIntegrationsModuleChange = useCallback(
    (mods: any) => {
      const containsIntegrationsModule = Boolean(data?.modules?.includes(Modules.Integrations));
      return containsIntegrationsModule && !mods.includes(Modules.Integrations);
    },
    [data?.modules]
  );

  const handleIsFound = useCallback(
    (array: Array<string>) => {
      if (skipIntegrationsModuleChange(array)) {
        dispatch(
          openToast({
            type: 'error',
            message: 'Cannot remove Integrations module once selected',
          })
        );
        return;
      }

      const drawings = Boolean(array.find((m: string) => m === Modules.Drawings));
      const integrations = Boolean(array.find((m: string) => m === Modules.Integrations));

      setEnabledModules({
        drawings,
        integrations,
      });
    },
    [skipIntegrationsModuleChange, dispatch]
  );

  const handleModuleFieldChange = useCallback(
    (event: React.ChangeEvent<{ value: any }>) => {
      handleIsFound(event.target.value);
    },
    [handleIsFound]
  );

  useEffect(() => {
    const getData = (data?.modules as Array<string>) || [];
    handleIsFound(getData);
  }, [data?.modules, getFieldValue, handleIsFound]);

  return (
    <ReactForm>
      <Form>
        {!!isEditing && <NameField isEditing={isEditing} />}
        <Address1Field isEditing={isEditing} />
        <Address2Field isEditing={isEditing} />
        <ZipCodeField isEditing={isEditing} />
        <CountryField isEditing={isEditing} />
        <CountyField isEditing={isEditing} />
        <StateProvinceField isEditing={isEditing} />
        <CityField isEditing={isEditing} />
        <PhoneField isEditing={isEditing} />
        <FaxField isEditing={isEditing} />
        <AccountStatusField isEditing={isEditing} />
        <UserCountField isEditing={isEditing} />
        {enabledModules.drawings && (
          <LoadingIndicator loading={loadingLicenses}>
            <DrawingsUsersCountField isEditing={isEditing} />
          </LoadingIndicator>
        )}
        <DateField
          fieldName="subscriptionStart"
          errorMessage="A Subscription/Trial start date is required"
          inputLabel="Subscription/Trial Start Date"
          isEditing={isEditing}
          id="SubStartField"
        />
        <DateField
          fieldName="subscriptionEnd"
          errorMessage="A Subscription/Trial end date is required"
          inputLabel="Subscription/Trial End Date"
          isEditing={isEditing}
          id="SubEndField"
        />
        <EnabledModulesField
          isEditing={isEditing}
          onChange={handleModuleFieldChange}
          skipOnChange={skipIntegrationsModuleChange}
        />
        {enabledModules.integrations && (
          <AccountingIntegrationsField
            isEditing={isEditing}
            isDisabled={isAccountingIntegrationDisabled}
          />
        )}
      </Form>

      {(isEditing || isNew) && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="button"
            id="CancelOrganizationFormButton"
            onClick={() => {
              if (isNew) {
                history.push('/organizations');
              } else {
                history.push(orgRouteMatch!.url);
              }
            }}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            id="SaveOrganizationFormButton"
            type="submit"
            loading={
              organizationUpdating || addingOrganization || isSavingLicenses || isUpdatingLicenses
            }
            disabled={!(isTouched && canSubmit)}
          >
            Save Changes
          </Button>
        </Box>
      )}
    </ReactForm>
  );
};

import React, { useMemo } from 'react';
import { Switch, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Fab, Tooltip } from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';
import { Page } from '@esub-engineering/react-component-library';
import { OrganizationView, CompanyView } from '../../containers';

export const OrganizationDetailPage = () => {
  const params = useParams<{ orgId: string }>();
  const history = useHistory();
  const matchOrgView = useRouteMatch('/organizations/:orgId');
  const matchCompanyList = useRouteMatch('/organizations/:orgId/companies');
  const { orgId } = params;

  const floatingAction = useMemo(() => {
    // create company
    if (matchCompanyList) {
      return {
        handler: () => history.push(`${matchCompanyList.url}/new`),
        label: 'Create a Company',
        icon: <Add />,
      };
    }

    // edit org
    return {
      handler: () => history.push(`${matchOrgView!.url}/edit`),
      label: 'Edit this Organization',
      icon: <Edit />,
    };
  }, [history, matchCompanyList, matchOrgView]);

  return (
    <Page
      floatingAction={
        <Tooltip title={floatingAction.label} placement="top-start">
          <Fab
            id="EditOrganizationButton"
            data-testid="AddCompany"
            color="primary"
            aria-label={floatingAction.label}
            onClick={floatingAction.handler}
          >
            {floatingAction.icon}
          </Fab>
        </Tooltip>
      }
    >
      <OrganizationView orgId={orgId} />
      <Switch>
        <Route exact path="/organizations/:orgId/companies/new">
          <CompanyView isNew isEditing onClose={() => history.push(matchCompanyList!.url)} />
        </Route>
        <Route exact path="/organizations/:orgId/companies/:companyId">
          <CompanyView onClose={() => history.push(matchCompanyList!.url)} />
        </Route>
        <Route exact path="/organizations/:orgId/companies/:companyId/edit">
          <CompanyView isEditing onClose={() => history.push(matchCompanyList!.url)} />
        </Route>
      </Switch>
    </Page>
  );
};

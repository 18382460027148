import React, { useCallback } from 'react';
import { useField } from 'react-form';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const AccountStatusField = React.memo(
  ({
    id = 'AccountStatusField',
    fieldName = 'accountStatus',
    errorMessage = 'An account status is required',
    isEditing,
  }: Partial<IEditableField>) => {
    const statuses = React.useMemo(
      () => [
        { id: 0, name: 'Active' },
        { id: 1, name: 'Trial' },
        { id: 2, name: 'Suspended' },
        { id: 3, name: 'Terminated' },
      ],
      []
    );

    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );

    const {
      value = '',
      setValue,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value as string);
    };

    return !isEditing ? (
      <DataField label="Account status" value={value} />
    ) : (
      <FormControl error={!!error} fullWidth>
        <InputLabel error={!!error} required>
          Account status
        </InputLabel>
        <Select
          required
          value={value}
          id={id}
          onChange={handleChange}
          error={!!error}
          inputProps={{
            'data-testid': 'account-status-select',
            'aria-label': 'Account status',
          }}
        >
          {statuses.map((status) => (
            <MenuItem key={status.id} value={status.name}>
              {status.name}
            </MenuItem>
          ))}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

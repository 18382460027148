import React from 'react';
import { Page, PageHeader } from '@esub-engineering/react-component-library';
import { LicenseList } from '../../containers';

export const LicensePage = () => (
  // const history = useHistory();

  <Page
  // floatingAction={
  //   <Tooltip title="Add an License" placement="top-start">
  //     <Fab
  //       id="AddLicense"
  //       color="primary"
  //       aria-label="Add an License"
  //       onClick={() => history.push('/licenses/new')}
  //     >
  //       <Add />
  //     </Fab>
  //   </Tooltip>
  // }
  >
    <PageHeader title="Licenses" />
    <LicenseList />
    {/* <Route path='/licenses/new'>
        <LicenseForm />
      </Route> */}
  </Page>
);

import React from 'react';
import { Container, Paper, Box } from '@material-ui/core';
import { VerificationAndPasswordSetWrapper } from '@esub-engineering/common-containers';
import { useDispatch } from 'react-redux';
import { Page } from '@esub-engineering/react-component-library';
import { openToast } from '../../../redux/toast';
import { EmptyPageWrapper } from '../../../containers';

export const VerificationAndPasswordSetPage = () => {
  const dispatch = useDispatch();

  return (
    <EmptyPageWrapper>
      <Page>
        <Container maxWidth="sm">
          <br />
          <Paper>
            <Box m={2} p={2}>
              <VerificationAndPasswordSetWrapper
                onSuccess={() =>
                  dispatch(
                    openToast({
                      type: 'success',
                      message: 'Password Reset Successful.',
                    })
                  )
                }
                onError={() =>
                  dispatch(
                    openToast({
                      type: 'error',
                      message: 'Unable to Reset Password.',
                    })
                  )
                }
              />
            </Box>
          </Paper>
        </Container>
      </Page>
    </EmptyPageWrapper>
  );
};

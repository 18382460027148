import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
} from '@material-ui/core';
import { LicenseProduct } from '@esub-engineering/platform-types';

export interface IDataTableProps {
  data: Array<LicenseProduct>;
  loading?: boolean;
}

export const DataTable = ({ data, loading = false }: IDataTableProps) => {
  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow role="row">
          <TableCell role="cell" align="center" colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    return data.map((row) => (
      <TableRow key={row.externalName} role="row">
        <TableCell role="cell">{row.internalName}</TableCell>
        <TableCell role="cell">{row.externalName}</TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="organizations table" role="table">
        <TableHead role="rowgroup">
          <TableRow role="rowheader">
            <TableCell role="columnheader">Internal Name</TableCell>
            <TableCell role="columnheader">External Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody role="rowgroup">{renderTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

// TODO: keep this DRY move to a package as soon as possible
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { useAuth } from '../../hooks/useAuth';

/**
 * Sync and verify token wrapper
 * @todo need to unify the approaches with current user, and create a real provider
 * @param props
 * @returns
 */
export const SyncToken = React.memo(
  ({ children }: { children: React.ReactNode | React.ReactElement }) => {
    const location = useLocation();

    const { syncAndVerifyTokens, getIdToken } = useAuth();

    // when the location changes we want to verify tokens, and potentially request a refresh
    useEffect(() => {
      syncAndVerifyTokens();
    }, [location, syncAndVerifyTokens]);

    // when location changes, and id token should be synced -- we want to set all future graph authorization calls
    const client = useGraphQLClient();

    useEffect(() => {
      const idToken = getIdToken();
      if (idToken) {
        client?.setHeader('Authorization', idToken);
      }
    }, [client, getIdToken]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
);

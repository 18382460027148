import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'react-form';
import { DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const CountyField = React.memo(
  ({ id = 'CountyField', fieldName = 'address.county', isEditing }: Partial<IEditableField>) => {
    const { value = '', getInputProps } = useField(fieldName, {});

    return !isEditing ? (
      <DataField label="County" value={value} />
    ) : (
      <TextField
        {...getInputProps()}
        label="County"
        id={id}
        inputProps={{
          'aria-label': 'County',
        }}
        value={value}
        fullWidth
      />
    );
  }
);

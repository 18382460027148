import React from 'react';
import { Paper, Box } from '@material-ui/core';
import { Page, PageHeader } from '@esub-engineering/react-component-library';
import { OrganizationForm } from '../../containers';

export const NewOrganizationPage = () => (
  <Page>
    <PageHeader title="Add Organization" />
    <Paper>
      <Box p={2}>
        <OrganizationForm isEditing isNew />
      </Box>
    </Paper>
  </Page>
);

import React from 'react';
import { Toast } from '@esub-engineering/react-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { closeToast, ToastType } from '../../redux/toast';
import { IEmptyPageWrapperProps } from './types';

// TODO: DRY move this with common redux page state... or refactor it to be context based
export const EmptyPageWrapper = ({ children }: IEmptyPageWrapperProps) => {
  const { open, message, autoHideDuration, type } = useSelector<{ toast: ToastType }, ToastType>(
    (state) => state.toast
  );

  const dispatch = useDispatch();

  return (
    <>
      {children}
      <Toast
        open={open}
        type={type}
        autoHideDuration={autoHideDuration}
        message={message}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => dispatch(closeToast())}
      />
    </>
  );
};

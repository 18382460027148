import React from 'react';
import { Form } from '../../components';
import { Address1Field } from '../Address1Field';
import { Address2Field } from '../Address2Field';
import { CityField } from '../CityField';
import { CountryField } from '../CountryField';
import { CountyField } from '../CountyField';
import { FaxField } from '../FaxField';
import { NameField } from '../NameField';
import { PhoneField } from '../PhoneField';
import { StateProvinceField } from '../StateProvinceField';
import { ZipCodeField } from '../ZipCodeField';
import { ICompanyForm } from '../../types';

export const CompanyForm = ({ isEditing }: ICompanyForm) => (
  <Form>
    <NameField isEditing={isEditing} />
    <Address1Field isEditing={isEditing} />
    <Address2Field isEditing={isEditing} />
    <ZipCodeField isEditing={isEditing} />
    <CountryField isEditing={isEditing} />
    <CountyField isEditing={isEditing} />
    <StateProvinceField isEditing={isEditing} />
    <CityField isEditing={isEditing} />
    <PhoneField isEditing={isEditing} />
    <FaxField isEditing={isEditing} />
  </Form>
);

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { datadogRum } from '@datadog/browser-rum';
import { getStageFromLocation } from '@esub-engineering/react-common-lib';
import App from './App';
import reportWebVitals from './reportWebVitals';

const stage = getStageFromLocation(window.location.hostname);

if (stage !== 'branch') {
  datadogRum.init({
    applicationId: '561ad3c2-df0a-4e77-be3f-a1ec365ddb5d',
    clientToken: 'pubc10d58de8cfdeebac9eefb97e403eb2a',
    site: 'datadoghq.com',
    service: 'esub-cloud-console-web-app',
    env: stage,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_APPLICATION_VERSION,
    sampleRate: 100,
    // @ts-ignore
    trackUserInteractions: true,
    allowedTracingOrigins: [/https:\/\/.*\.esubcloud\.com/],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { TableSearchBar } from '@esub-engineering/react-component-library';

export interface IOrganizationData {
  accountStatus: string;
  name: string;
  phone: string;
  subscriptionEnd: string;
  subscriptionStart: string;
  id: string;
  country: string;
}

export interface IDataTableProps {
  data: Array<IOrganizationData>;
  onFilterChange?: (value: string) => void;
  filterLabel?: string;
  filterValue?: string | null;
  loading?: boolean;
}

export const DataTable = ({
  data,
  onFilterChange,
  filterLabel,
  filterValue,
  loading = false,
}: IDataTableProps) => {
  const searchFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onFilterChange) {
      onFilterChange(e.target.value);
    }
  };

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow role="row">
          <TableCell role="cell" align="center" colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    return data.map((row) => (
      <TableRow key={row.id} role="row">
        <TableCell role="cell">
          <Link to={`/organizations/${row.id}`}>{row.name}</Link>
        </TableCell>
        <TableCell role="cell">{row.accountStatus}</TableCell>
        <TableCell role="cell">{row.phone}</TableCell>
        <TableCell role="cell">{`${row.subscriptionStart} - ${row.subscriptionEnd}`}</TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="organizations table" role="table">
        <TableHead role="rowgroup">
          <TableSearchBar
            id="FilterOrganization"
            label={filterLabel!}
            placeholder={filterLabel!}
            value={filterValue}
            onChange={searchFieldChange}
            colSpan={3}
            autoFocus
          />
          <TableRow role="rowheader">
            <TableCell role="columnheader">Name</TableCell>
            <TableCell role="columnheader">Status</TableCell>
            <TableCell role="columnheader">Phone</TableCell>
            <TableCell role="columnheader">Subscription</TableCell>
          </TableRow>
        </TableHead>
        <TableBody role="rowgroup">{renderTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ProtectedRoute } from '@esub-engineering/common-containers';
import { PageWrapper } from '../containers';
import {
  LoginPage,
  NotFoundPage,
  ActivateAccountPage,
  ConfirmationPasswordSetPage,
  RequestVerificationCodePage,
  VerificationAndPasswordSetPage,
  OrganizationsPage,
  OrganizationDetailPage,
  NewOrganizationPage,
  EditOrganizationPage,
} from '../pages';
import { SyncToken } from '../auth';
import { LicensePage } from '../pages/LicensePage';

const Routes = () => {
  const pages: {
    [key: string]: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  } = {
    '/organizations/new': NewOrganizationPage,
    '/organizations/:orgId/edit': EditOrganizationPage,
    '/organizations/:orgId': OrganizationDetailPage,
    '/organizations': OrganizationsPage,
    '/licenses': LicensePage,
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/organizations" />
        </Route>

        <Route exact path="/login">
          <LoginPage />
        </Route>

        <Route exact path="/activate">
          <ActivateAccountPage />
        </Route>

        <Route exact path="/forgotten-password">
          <RequestVerificationCodePage />
        </Route>

        <Route exact path="/password-confirmed">
          <ConfirmationPasswordSetPage />
        </Route>

        <Route exact path="/set-password">
          <VerificationAndPasswordSetPage />
        </Route>
        <Route path={Object.keys(pages)}>
          <SyncToken>
            {/* Pages can be wrapped in this common navigation and layout */}
            <PageWrapper>
              <Switch>
                {Object.keys(pages).map((key) => (
                  <ProtectedRoute key={key} path={key} component={pages[key]} />
                ))}
              </Switch>
            </PageWrapper>
          </SyncToken>
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: '1%',
      minWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(1) / 2,
    },
    noLabel: {
      marginTop: '3%',
    },
  })
);

import React from 'react';
import { MaskedInput, DataField } from '@esub-engineering/react-component-library';
import { useField } from 'react-form';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const FaxField = React.memo(
  ({ id = 'FaxField', fieldName = 'fax', isEditing }: Partial<IEditableField>) => {
    const {
      value = '',
      getInputProps,
      meta: { error },
    } = useField(fieldName, {});

    const { onChange, ...rest } = getInputProps();

    return !isEditing ? (
      <DataField label="Fax" value={value} />
    ) : (
      <MaskedInput
        id={id}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        textFieldProps={{
          ...rest,
          label: 'Fax',
          variant: 'standard',
          error: !!error,
          helperText: error,
          fullWidth: true,
          value,
        }}
        onChange={onChange}
      />
    );
  }
);

import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { companyQueries } from '@esub-engineering/react-common-lib';
import { DataTable } from './DataTable';
import { openToast } from '../../redux/toast';
import { ICompaniesList, ICompanyItem } from '../../types';

export const CompanyList = () => {
  // redux
  const dispatch = useDispatch();

  // router
  const params = useParams<{ orgId: string }>();
  const { orgId } = params;

  // query
  const client = useGraphQLClient();

  const { data, isLoading } = useQuery<ICompaniesList, Error>(
    companyQueries.key,
    () => client!.request(companyQueries.listAdminCompanies, { orgId }),
    {
      onError: () => {
        dispatch(
          openToast({
            type: 'error',
            message: 'Unable to fetch list of companies',
          })
        );
      },
    }
  );

  let companyList: Array<ICompanyItem> = [];

  if (data) {
    companyList = data?.listAdminCompanies?.items!;
  }

  return <DataTable data={companyList} loading={isLoading} />;
};

import React, { useCallback } from 'react';
import { useField } from 'react-form';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DataField } from '@esub-engineering/react-component-library';
import { availableModules, IEditableField } from '@esub-engineering/react-common-lib';
import useStyles from './styles';

export const EnabledModulesField = React.memo(
  ({
    id = 'ModulesField',
    fieldName = 'modules',
    errorMessage,
    isEditing,
    skipOnChange = () => false,
    onChange,
  }: Partial<IEditableField> & {
    onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
    skipOnChange?: (event: React.ChangeEvent<{ value: unknown }>) => boolean;
  }) => {
    const classes = useStyles();

    const modules = React.useMemo(() => Array.from(availableModules), []);

    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );

    const {
      value = [],
      setValue,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
      if (onChange) onChange(event);
      if (!skipOnChange(event.target.value)) {
        setValue(event.target.value as string);
      }
    };

    return !isEditing ? (
      <DataField label="Enabled modules" value={value} />
    ) : (
      <FormControl error={!!error} fullWidth>
        <InputLabel id="EnabledModulesSelect" error={!!error}>
          Enabled modules
        </InputLabel>
        <Select
          inputProps={{
            'aria-label': 'Enabled modules',
            'data-testid': 'enabled-modules-select',
          }}
          value={value}
          id={id}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((optionValue) => (
                <Chip key={optionValue} label={optionValue} className={classes.chip} />
              ))}
            </div>
          )}
          error={!!error}
          multiple
        >
          {modules.map((module) => (
            <MenuItem key={module.value} value={module.value}>
              {module.text}
            </MenuItem>
          ))}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

import React from 'react';
import { TextField, DataField } from '@esub-engineering/react-component-library';
import { useField } from 'react-form';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const ZipCodeField = React.memo(
  ({ id = 'ZipCodeField', fieldName = 'address.zipCode', isEditing }: Partial<IEditableField>) => {
    const { value = '', getInputProps } = useField(fieldName, {});

    return !isEditing ? (
      <DataField label="Zip Code" value={value} />
    ) : (
      <TextField
        {...getInputProps()}
        label="Zip Code"
        id={id}
        inputProps={{
          'aria-label': 'Zip Code',
        }}
        value={value}
        required
        fullWidth
      />
    );
  }
);

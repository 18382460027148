import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    content: {
      minWidth: '600px',
    },
    infoField: {
      paddingBottom: theme.spacing(2),
    },
  })
);

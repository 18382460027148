import React from 'react';
import { Fab, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Page, PageHeader } from '@esub-engineering/react-component-library';
import { OrganizationList } from '../../containers';

export const OrganizationsPage = () => {
  const history = useHistory();

  return (
    <Page
      floatingAction={
        <Tooltip title="Add an Organization" placement="top-start">
          <Fab
            id="AddOrganization"
            color="primary"
            aria-label="Add an Organization"
            onClick={() => history.push('/organizations/new')}
          >
            <Add />
          </Fab>
        </Tooltip>
      }
    >
      <PageHeader title="Organizations" />
      <OrganizationList />
    </Page>
  );
};

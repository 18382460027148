import React, { useMemo } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { PageHeader, TabView, ITabClickEventParams } from '@esub-engineering/react-component-library';
import { useQuery } from 'react-query';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { organizationQueries } from '@esub-engineering/react-common-lib';
import { OrganizationForm } from '../OrganizationForm';
import { IOrganization } from '../../types';
import { CompanyList } from '../CompanyList';
import { openToast } from '../../redux/toast';
import { IOrganizationViewProps } from './types';

export const OrganizationView = ({ orgId, isEditing }: IOrganizationViewProps) => {
  // redux
  const dispatch = useDispatch();

  // router
  const history = useHistory();
  const match = useRouteMatch('/organizations/:orgId/companies');

  // queries
  const { key, getOrganization } = organizationQueries;

  const client = useGraphQLClient();

  const { data, isLoading, isFetching } = useQuery<IOrganization, Error>(
    [key, orgId], // composite key for this particular record
    async () => {
      const result = await client!.request(getOrganization, { id: orgId });
      const xform = result?.getOrganization;

      xform.modules = xform?.modules?.length ? xform?.modules : [];

      return xform;
    },
    {
      onError: () => {
        dispatch(
          openToast({
            type: 'error',
            message: 'Unable to fetch organization details',
          })
        );
      },
    }
  );

  const tabs = useMemo(
    () => [
      {
        id: 'OrganizationDetailsTab',
        panelId: 'OrganizationDetailsPanel',
        'data-testid': 'organization-view-tab',
        label: 'Details',
        to: `/organizations/${orgId}`,
        onClick: ({ to }: ITabClickEventParams) => {
          history.push(to!);
        },
      },
      {
        id: 'OrganizationCompaniesTab',
        panelId: 'OrganizationCompaniesPanel',
        'data-testid': 'organization-view-tab',
        label: 'Companies',
        to: `/organizations/${orgId}/companies`,
        onClick: ({ to }: ITabClickEventParams) => {
          history.push(to!);
        },
      },
    ],
    [history, orgId]
  );

  const panels = useMemo(
    () => [
      {
        id: 'OrganizationDetailsPanel',
        children: <OrganizationForm data={data} isEditing={isEditing} orgId={orgId} />,
      },
      {
        id: 'OrganizationCompaniesPanel',
        children: <CompanyList />,
      },
    ],
    [data, isEditing, orgId]
  );

  return (
    <>
      <PageHeader title="Organizations" subtitle={data && data.name} />

      {/* loading */}
      {isLoading && <CircularProgress />}

      {/* empty */}
      {!data && !isFetching && (
        <Typography data-testid="not-found-message">Organization Not Found</Typography>
      )}

      {data && (
        <TabView
          defaultValue={match ? 1 : 0}
          label="organization tabs"
          tabs={tabs}
          panels={panels}
        />
      )}
    </>
  );
};

import {
  LicenseOrgAllocations,
  LicenseProductType,
  ListLicenseOrgAllocationsAdminInput,
} from '@esub-engineering/platform-types';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { licenseQueries } from '@esub-engineering/react-common-lib';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useToast } from '../../utilityHooks';

export const useListLicenseOrgAllocationsAdmin = (
  options: UseQueryOptions<ListLicenseOrgAllocationsAdminInput, Error, LicenseOrgAllocations> = {}
) => {
  const { openErrorToast } = useToast();

  const client = useGraphQLClient();

  const callback = useCallback(
    async () => {
      const licenses = await client!.request(licenseQueries.listLicenseOrgAllocationsAdmin, {
        limit: 20,
        productId: LicenseProductType.Drawings,
      });
      return licenses.listLicenseOrgAllocationsAdmin || licenses;
    },
    [client]
  );

  return useQuery<ListLicenseOrgAllocationsAdminInput, Error, LicenseOrgAllocations>(
    [licenseQueries.key],
    callback,
    {
      onError: () => openErrorToast('Unable to fetch list of licenses'),
      ...options,
    }
  );
};

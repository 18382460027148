import React from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '@esub-engineering/react-component-library';
import { OrganizationView } from '../../containers';

export const EditOrganizationPage = () => {
  const { orgId }: { orgId: string } = useParams();

  // TODO: we should be able to remove this page
  return (
    <Page>
      <OrganizationView orgId={orgId} isEditing />
    </Page>
  );
};

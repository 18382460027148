import React, { useMemo, useCallback } from 'react';
import {
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'react-query';
import { useForm } from 'react-form';
import { useParams, useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { companyQueries } from '@esub-engineering/react-common-lib';
import { Button } from '@esub-engineering/react-component-library';
import { openToast } from '../../redux/toast';
import { CompanyForm } from '../CompanyForm';
import { ICompany, ICompanyMutation } from '../../types';
import { ICompanyViewProps } from './types';
import { useStyles } from './styles';

export const CompanyView = ({ isNew, isEditing, onClose }: ICompanyViewProps) => {
  // styles
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  // redux
  const dispatch = useDispatch();

  // router
  const history = useHistory();
  const { companyId, orgId } = useParams<{ companyId: string; orgId: string }>();

  const { key, getAdminCompany, updateAdminCompany, createAdminCompany } = companyQueries;

  const client = useGraphQLClient();

  // fetch companies
  const { data, isLoading, isFetching } = useQuery<ICompany, Error>(
    [key, companyId], // composite key for this particular record
    async () => {
      // new company form... dont fetch
      if (isNew) return undefined;

      // existing company... time to fetch
      const result = await client!.request(getAdminCompany, { id: companyId, orgId });
      return result?.getAdminCompany;
    },
    {
      onError: () => {
        dispatch(
          openToast({
            type: 'error',
            message: 'Unable to fetch company details',
          })
        );
      },
    }
  );

  // edit and create company
  const mutateCallback = useCallback(
    (company: ICompanyMutation) =>
      isEditing && !isNew
        ? client!.request(updateAdminCompany, company)
        : client!.request(createAdminCompany, company),
    [client, isEditing, isNew, updateAdminCompany, createAdminCompany]
  );

  const { mutate, isLoading: isCompanyMutateLoading } = useMutation<
    ICompany,
    Error,
    ICompanyMutation
  >(mutateCallback, {
    mutationKey: isNew ? key : [key, data?.id],
    onSuccess: async () => {
      // show toast
      dispatch(
        openToast({
          message: 'Changes saved',
        })
      );

      // close dialog
      onClose();
    },
    onError: () => {
      dispatch(
        openToast({
          type: 'error',
          message: 'Unable to save the company',
        })
      );
    },
  });

  // form
  const defaultFormValues = useMemo(
    () => ({
      name: data?.name,
      address: {
        ...data?.address,
      },
      phone: data?.phone,
      fax: data?.fax,
      country: data?.country,
    }),
    [data]
  );

  const {
    Form: ReactForm,
    meta: { canSubmit, isTouched },
  } = useForm({
    defaultValues: defaultFormValues,
    onSubmit: async (values) => {
      const companyData = values as unknown as ICompany;
      companyData.orgId = orgId;

      if (isEditing) {
        companyData.id = companyId;
      }

      await mutate({ input: companyData });
    },
    debugForm: false,
  });

  return (
    <Dialog
      open // if its rendered its open
      fullScreen={fullScreen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <ReactForm>
        <DialogTitle id="form-dialog-title">
          {/* new company */}
          {isNew && 'Add a New Company'}

          {/* existing company */}
          {data && (
            <span>
              Company
              <em>{` "${data.name}"`}</em>
            </span>
          )}

          {/* fallback */}
          {!data && !isNew && !isFetching && 'Company Not Found'}
        </DialogTitle>

        <DialogContent className={classes.content}>
          <>
            {/* loading */}
            {isLoading && <CircularProgress />}

            {/* empty */}
            {!data && !isFetching && !isNew && (
              <Typography data-testid="not-found-message">Company Not Found</Typography>
            )}

            {/* data found OR new company */}
            {(data || isNew) && <CompanyForm data={data} isEditing={isEditing} />}
          </>
        </DialogContent>
        <DialogActions>
          {isEditing || isNew ? (
            <>
              {/* saving or creating a new company */}
              <Button
                id="CancelCompanyFormButton"
                onClick={() => {
                  if (isNew) {
                    history.push(`/organizations/${orgId}/companies`);
                  } else {
                    history.push(`/organizations/${orgId}/companies/${companyId}`);
                  }
                }}
              >
                Cancel
              </Button>

              <Button
                id="SaveCompanyFormButton"
                type="submit"
                loading={isCompanyMutateLoading}
                disabled={!(isTouched && canSubmit)}
              >
                Save Changes
              </Button>
            </>
          ) : (
            <>
              <Button
                id="EditCompanyFormButton"
                onClick={() => history.push(`/organizations/${orgId}/companies/${companyId}/edit`)}
                disabled={isLoading}
                color="primary"
              >
                Edit
              </Button>

              {/* company details */}
              <Button id="CloseCompanyFormButton" onClick={onClose} color="primary">
                Close
              </Button>
            </>
          )}
        </DialogActions>
      </ReactForm>
    </Dialog>
  );
};

import React, { useCallback } from 'react';
import { useField } from 'react-form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DataField } from '@esub-engineering/react-component-library';
import { usProvinces, canadianProvinces, IEditableField } from '@esub-engineering/react-common-lib';

export const StateProvinceField = React.memo(
  ({
    id = 'StateField',
    fieldName = 'address.state',
    errorMessage = 'A state/province is required',
    isEditing,
  }: Partial<IEditableField>) => {
    const provinces = React.useMemo(
      () => [
        // TODO: this can be based on country prop and province map in the future
        ...Array.from(usProvinces),
        ...Array.from(canadianProvinces),
      ],
      []
    );

    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );
    const {
      value = '',
      setValue,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value as string);
    };

    return !isEditing ? (
      <DataField label="State/Province" value={value} />
    ) : (
      <FormControl error={!!error} fullWidth>
        <InputLabel error={!!error} required>
          State/Province
        </InputLabel>
        <Select
          inputProps={{
            'data-testid': 'state-select',
            'aria-label': 'State/Province',
          }}
          id={id}
          value={value}
          onChange={handleChange}
          error={!!error}
          required
        >
          {provinces.map((province) => (
            <MenuItem key={province.value} value={province.value}>
              {province.text}
            </MenuItem>
          ))}
        </Select>

        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

import React from 'react';
import { Container, Paper, Box } from '@material-ui/core';
import { UserLogin } from '@esub-engineering/common-containers';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Page } from '@esub-engineering/react-component-library';
import { openToast } from '../../../redux/toast';
import { EmptyPageWrapper } from '../../../containers';

export const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <EmptyPageWrapper>
      <Page>
        <Container fixed maxWidth="sm">
          <br />
          <Paper>
            <Box p={2}>
              <UserLogin
                subtitle="to continue to eSUB Console"
                onSuccess={() => {
                  history.push('/');
                  history.go(0);
                }}
                onError={() =>
                  dispatch(
                    openToast({
                      type: 'error',
                      message: 'Unable to login',
                    })
                  )
                }
              />
            </Box>
          </Paper>
        </Container>
      </Page>
    </EmptyPageWrapper>
  );
};

import React, { useMemo } from 'react';
import { DataTable } from './DataTable';
import { useListLicenseProducts } from '../../ApiHooks/useListLicenseProducts';

const LicenseList = () => {
  const { data, isLoading } = useListLicenseProducts();

  const memoizedData = useMemo(() => data?.items, [data])

  return (
    <DataTable
      data={memoizedData||[]}
      loading={isLoading}
    />
  );
}

export { LicenseList };

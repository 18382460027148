import React, { useCallback } from 'react';
import { useField } from 'react-form';
import { MaskedInput, DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';

export const PhoneField = React.memo(
  ({
    id = 'PhoneField',
    fieldName = 'phone',
    errorMessage = 'A phone number is required',
    isEditing,
  }: Partial<IEditableField>) => {
    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );
    const {
      value = '',
      getInputProps,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    const { onChange, ...rest } = getInputProps();

    return !isEditing ? (
      <DataField label="Phone" value={value} />
    ) : (
      <MaskedInput
        id={id}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        textFieldProps={{
          ...rest,
          label: 'Phone',
          variant: 'standard',
          error: !!error,
          helperText: error,
          required: true,
          fullWidth: true,
          value,
        }}
        onChange={onChange}
      />
    );
  }
);

import React from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';

import { ICompanyItem } from '../../../types';

export interface IDataTableProps {
  data: Array<ICompanyItem>;
  // eslint-disable-next-line react/no-unused-prop-types
  onFilterChange?: (value: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  filterLabel?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  filterValue?: string | null;
  loading?: boolean;
}

export const DataTable = ({ data, loading = false }: IDataTableProps) => {
  const {
    orgId,
  }: {
    orgId: string;
  } = useParams();

  const renderLoadingBody = () => (
    <TableRow role="row">
      <TableCell role="cell" align="center" colSpan={4}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  const renderTableBody = () =>
    data.map((row) => (
      <TableRow key={row.id} role="row">
        <TableCell role="cell" data-testid={row.id} data-testname={row.name}>
          <Link to={`/organizations/${orgId}/companies/${row.id}`}>{row.name}</Link>
        </TableCell>
        <TableCell role="cell">{row.phone}</TableCell>
        <TableCell role="cell">{row.country}</TableCell>
      </TableRow>
    ));

  return (
    <TableContainer>
      <Table aria-label="companies table" role="table">
        <TableHead role="rowgroup">
          <TableRow role="rowheader">
            <TableCell role="columnheader">Name</TableCell>
            <TableCell role="columnheader">Phone</TableCell>
            <TableCell role="columnheader">Country</TableCell>
          </TableRow>
        </TableHead>
        <TableBody role="rowgroup">{loading ? renderLoadingBody() : renderTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

import React, { useCallback } from 'react';
import { useField } from 'react-form';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DataField } from '@esub-engineering/react-component-library';
import { IEditableField } from '@esub-engineering/react-common-lib';

export interface IDateField extends Partial<IEditableField> {
  fieldName: string;
  inputLabel: string;
}

export const DateField = React.memo(
  ({ id = 'DateField', fieldName, errorMessage, inputLabel, isEditing }: IDateField) => {
    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );

    const {
      value = null,
      setValue,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    const handleChange = (dateValue: Date | null) => {
      setValue(dateValue);
    };

    return !isEditing ? (
      <DataField label={inputLabel} value={format(new Date(value), 'MM/dd/yyyy')} />
    ) : (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          inputProps={{
            'data-testid': 'date-select',
            'aria-label': inputLabel,
          }}
          id={id}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={inputLabel}
          value={value}
          onChange={(date) => handleChange(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={!!error}
          helperText={error}
          required
          disablePast
          fullWidth
        />
      </MuiPickersUtilsProvider>
    );
  }
);

import React, { useCallback } from 'react';
import { useField } from 'react-form';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DataField } from '@esub-engineering/react-component-library';
import { countries, IEditableField } from '@esub-engineering/react-common-lib';

export const CountryField = React.memo(
  ({
    id = 'CountryField',
    fieldName = 'country',
    errorMessage = 'A country is required',
    isEditing,
  }: Partial<IEditableField>) => {
    const countryOptions = React.useMemo(() => Array.from(countries), []);

    const validate = useCallback(
      (inputValue, { setMeta }) => {
        if (!inputValue) {
          setMeta({ error: errorMessage });
        } else {
          setMeta({ error: null });
          return undefined;
        }

        return errorMessage;
      },
      [errorMessage]
    );

    const {
      value = '',
      setValue,
      meta: { error },
    } = useField(fieldName, {
      validate,
    });

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value as string);
    };

    return !isEditing ? (
      <DataField label="Country" value={value} />
    ) : (
      <FormControl error={!!error} fullWidth>
        <InputLabel error={!!error} required>
          Country
        </InputLabel>
        <Select
          label="Country"
          value={value}
          id={id}
          onChange={handleChange}
          inputProps={{
            'data-testid': 'country-select',
            'aria-label': 'Country',
          }}
          error={!!error}
          required
        >
          {countryOptions.map((country) => (
            <MenuItem key={country.value} value={country.value}>
              {country.text}
            </MenuItem>
          ))}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
